import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FooterDark";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

import heroScreenshotImageSrc from "images/prototype-illustration.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>We also provide best</Subheading>}
        heading="Data Integration"
        description={"services to bulk load labels into your backend, merge different datasets to make your ML models efficient or build enterprise datamarts"}
        buttonRounded={false}
        primaryButtonText=""
        imageSrc={heroScreenshotImageSrc}
      />
      <Features
        subheading={<Subheading>-</Subheading>}
        heading="Our vast expertise in data integration"
        description={"includes building ETL packages for merging varied datasets, datamarts for industry giants in 4 countries"}
        cards={[
          {
            imageSrc: ShieldIconImage,
            title: "Enterprise level",
            description: "experience with tools like SSIS, SSAS, WhereScape RED etc "
          },
          {
            imageSrc: ShieldIconImage,
            title: "18 + ",
            description: "years of industry experience"
          },
          { imageSrc: ReliableIconImage, title: "Affordable", 
            description : "but at enterprise quality"
          }
          
        ]}
        linkText=""
      />
      <Footer />
    </AnimationRevealPage>
  );
};
