import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/Home_TwoColumnWithInput";
import Features from "components/features/Home_3ColServices";
import MainFeature from "components/features/Home_TwoColWithButtons";
import MainFeature2 from "components/features/Home_TwoColTwoSubs";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/Home_GetStarted";
import Footer from "components/footers/FooterDark";
import heroScreenshotImageSrc from "images/undraw_team_collaboration_re_ow29.svg";
import macHeroScreenshotImageSrc from "images/stats-illustration.svg";
import prototypeIllustrationImageSrc from "images/undraw_hooked_re_vl59.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";


import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>-</Subheading>}
        heading="Engage and hook"
        description={"your customers with short videos we curated just for your business and your customers. Keep your customers engaged, happy and offer more than your product"}
        cards={[
          { imageSrc: CustomizeIconImage, title: "Curated",
            description : "",
          },
          { imageSrc: SimpleIconImage, title: "Mobile",
            description : ""
          },
        ]}
        linkText=""
      />
      <MainFeature
        heading = {(
          <>
          Curated <span tw="text-primary-500"> videos</span> 
          </>
          )}
        description = {"specially for your business and customers taste"}
        subheading={<Subheading>We deliver</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <MainFeature2
        subheading={<Subheading>Delivered to </Subheading>}
        heading={
          <>
            customers <HighlightedText>Mobile</HighlightedText>
          </>
        }
        description = "via our app when they visit your store or when they receive your products"
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
      />
      
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
